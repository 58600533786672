/* eslint-disable import/prefer-default-export */

export const THEMES = {
  LIGHT: 'LIGHT',
  ONE_DARK: 'ONE_DARK',
  UNICORN: 'UNICORN',
};

export const ROLE = {
  ID_SYSTEM_ADMIN:      1,
  ID_CLUB_ADMIN:        2,
  ID_PLAYER:            5,
  // ID_PENDING_EMAIL:     10,
  // ID_PENDING_GOLFLINK:  11,
};
